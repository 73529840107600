export const determineContent = (
	branchBannerAssignment: string,
	isMobile: boolean,
) => {
	if (branchBannerAssignment === 'new-banner' && isMobile) {
		return 'banner-1';
	}

	return 'blue-pill';
};
