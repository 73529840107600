import React, { FC } from 'react';
import Styles from './ContactedVendors.scss';
import { RoundVendorCard } from './RoundVendorCard';

interface ContactedVendorsProps {
	contactedVendors: API.FESharedProfile[];
	sourcePage: string;
}

export const ContactedVendors: FC<ContactedVendorsProps> = ({
	contactedVendors,
	sourcePage,
}) => {
	return (
		<div className={Styles.contactedVendorsFrame}>
			{contactedVendors.map((vendor) => (
				<RoundVendorCard
					key={vendor.id}
					vendor={vendor}
					sourcePage={sourcePage}
				/>
			))}
		</div>
	);
};
