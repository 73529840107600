import NavigableModal, {
	NavigableModalProps,
} from '@components/NavigableModal/NavigableModal';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Button } from '@xo-union/tk-component-buttons';
import { DisplayLink } from '@xo-union/tk-ui-links';
import { Body1, Editorial } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { VFC } from 'react';
import { ModalBackground } from '../components/ModalBackground';
import { TrackConfModalViewedProperties } from '../types';
import Styles from './VRMConfirmationModalV2.scss';
import { ContactedVendors } from './components/ContactedVendors';
import { INBOX_URL } from './settings';
import {
	ACTION_CLICK_INBOX,
	ACTION_CLICK_KEEP_GOING,
	ACTION_DISMISS,
	TRACKING_REASON,
	VRM_CONFIRMATION_MODAL_TYPE,
} from './utils/constants';
import {
	trackConfirmationModalCtaClick,
	trackConfirmationModalViewed,
} from './utils/tracking';

export interface VRMConfirmationModalV2Props
	extends Omit<NavigableModalProps, 'hash'> {
	closeCallback: () => void;
	recommendedVendors: API.FESharedProfile[];
	contactedVendors: API.FESharedProfile[];
	indirectLeadsCount: number;
	onNextButtonClick: () => void;
	sourcePage: string;
}

export const VRM_CONFIRMATION_ANIMATION_DURATION = 1300;

const VRMConfirmationModalV2: VFC<VRMConfirmationModalV2Props> = ({
	recommendedVendors,
	contactedVendors,
	indirectLeadsCount,
	onNextButtonClick,
	sourcePage,
	...props
}) => {
	const { track } = useAnalyticsContext();
	const [isModalClosing, setIsModalClosing] = React.useState(false);

	trackConfirmationModalViewed(track, {
		contacted_vendor_count: contactedVendors.length,
		modal_type: VRM_CONFIRMATION_MODAL_TYPE,
	});

	const setCtaTrackProperties = (
		action: string,
		selection: string,
	): TrackConfModalViewedProperties => {
		return {
			source_page: sourcePage,
			action,
			selection,
			reason: TRACKING_REASON,
		};
	};

	const handleModalClose = () => {
		setIsModalClosing(true);
		setTimeout(() => {
			setIsModalClosing(false);
			props.closeCallback();
		}, VRM_CONFIRMATION_ANIMATION_DURATION);
		trackConfirmationModalCtaClick(
			track,
			setCtaTrackProperties(ACTION_DISMISS, 'close'),
		);
	};

	return (
		<NavigableModal
			hash="vrm-confirmation-modal"
			isModalOpen={props.isModalOpen}
			closeCallback={handleModalClose}
			onOverlayClick={handleModalClose}
			className={classNames(
				Styles.modal,
				isModalClosing ? Styles.modalClosing : '',
			)}
		>
			<ModalBackground />
			<div className={Styles.itemContainer}>
				<Editorial bold>You’re on a roll</Editorial>
				<Body1>
					You messaged {indirectLeadsCount} more vendor
					{indirectLeadsCount === 1 ? '' : 's'}. Nice job.
				</Body1>
				<DisplayLink
					href={INBOX_URL}
					color="secondary"
					size="lg"
					target="_blank"
					onClick={() =>
						trackConfirmationModalCtaClick(
							track,
							setCtaTrackProperties(
								ACTION_CLICK_INBOX,
								'See your vendor inbox',
							),
						)
					}
				>
					See your vendor inbox
				</DisplayLink>
				<ContactedVendors
					contactedVendors={contactedVendors}
					sourcePage={sourcePage}
				/>
				<Button
					color="primary"
					size="md"
					onClick={() => {
						setIsModalClosing(true);
						setTimeout(() => {
							setIsModalClosing(false);
							onNextButtonClick();
						}, VRM_CONFIRMATION_ANIMATION_DURATION);
						trackConfirmationModalCtaClick(
							track,
							setCtaTrackProperties(ACTION_CLICK_KEEP_GOING, 'Keep going'),
						);
					}}
				>
					Keep Going
				</Button>
			</div>
		</NavigableModal>
	);
};

export default VRMConfirmationModalV2;
