import * as guards from '@api/helpers/guards';
import report from '@api/helpers/report';
import axios from '@axios';
import * as settings from '../../settings';
import pvRecAnonQuery from './query';

export const fetchPvRecAnon = async (
	categoryCode: string,
	location: Vendor.CityState,
	marketCode: string,
	limit: number,
	anonymousId: string | null = null,
): Promise<API.PvRecAnonTemplate> => {
	const variables = {
		anonymousId,
		location,
		categoryCode,
		limit,
		apiKey: settings.pvRecApiKey,
	};
	const data = {
		query: pvRecAnonQuery,
		variables,
	};
	try {
		const { data: responseData } =
			await axios.post<API.AnonSuggestionsResponse>(data);
		const { pvRecAnon } = responseData.data;
		if (pvRecAnon === null) {
			const errors = guards.isAPIErrorResponse(responseData)
				? responseData.errors
				: [];
			const invalidResponse: API.InvalidResponse = {
				response: errors[0],
				isInvalid: true,
			};
			throw invalidResponse;
		}
		return pvRecAnon;
	} catch (error) {
		report('fetchPvRecAnon', error, { ...variables, marketCode });
	}

	return {
		profiles: [],
	};
};
