import { FEATURE_FLAGS } from '@feature-flags/constants';
import { useFeatureFlags } from '@feature-flags/use-feature-flags';
import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai';
import { hasStartingCostAtom } from '../../../jotai/storefront/detailed-pricing';

export const useStorefrontFeatureBvcPlusPricing = () => {
	const featureFlags = useFeatureFlags();
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const hasStartingCost = useAtomValue(hasStartingCostAtom);

	return Boolean(
		featureFlags[FEATURE_FLAGS.STOREFRONT_DETAILED_PRICING] &&
			vendor?.isPaid &&
			hasStartingCost,
	);
};
