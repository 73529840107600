import * as AlertActions from '@redux/alert/actionCreators';
import * as ExperimentActions from '@redux/experiments';
import { vendorRecommendationDistanceSelector } from '@redux/experiments/selectors/vendor-recommendation-distance';
import { VRMAIPhotoSelector } from '@redux/experiments/selectors/vrm-ai-photo';
import { getSimilarVendors as getSimilarVendorsAction } from '@redux/vrm/thunks';
import { experiments } from '@settings';
import { State } from '@typings/redux';
import { buildSingleLeadSuccessCopy } from '@utils';
import { noop } from '@utils/noop';
import React, {
	createContext,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { connect } from 'react-redux';
import { InlineRfqSourceContent } from 'types/sourceContent';
import { Raw, Similar } from 'types/vendor';
import { GetSimilarVendors } from '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/RFQModal.d';
import {
	MAX_VRM_VENDORS_DISPLAYED,
	VRM_VENDORS_REQUESTED,
	filterVendors,
	selectUserMaxGuestCount,
} from '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/utils';

export interface RecommendedContextPropsValue {
	modal: string;
	onClose: () => void;
	recommendedVendors: Similar[];
	setModal: (modal: string) => void;
	openModal: (sourceContent: InlineRfqSourceContent) => void;
	initiator:
		| {
				sourceContent: InlineRfqSourceContent;
		  }
		| Record<string, unknown>;
	toggleVRM: (status: boolean) => void;
	isUserRegistered?: boolean;
	userMaxGuestCount?: number;
	vendorRecommendationDistanceAssignment?: string;
	vrmAIPhotoAssignment?: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;
interface DispatchProps {
	getSimilarVendors: GetSimilarVendors;
	setContentAndStatus: (content: string, status: boolean) => void;
	reportServerSideExperiment: (idExperiment: Experiments.Id) => void;
}
type RecommendedContextProps = StateProps & DispatchProps;

const RecommendedContext = createContext<RecommendedContextPropsValue>({
	modal: '',
	onClose: noop,
	recommendedVendors: [],
	openModal: noop,
	setModal: noop,
	toggleVRM: noop,
	initiator: {},
	userMaxGuestCount: 0,
	vendorRecommendationDistanceAssignment: '',
	vrmAIPhotoAssignment: '',
});

const redirectToUpsell = (
	name: string,
	setContentAndStatus: (content: string, status: boolean) => void,
	isUserRegistered?: boolean,
) => {
	if (isUserRegistered) {
		const alertContent = buildSingleLeadSuccessCopy(name);
		setContentAndStatus(alertContent, true);
	}
};

const ContextProvider: FC<RecommendedContextProps> = (props) => {
	const {
		children,
		getSimilarVendors,
		messagedVendors,
		pageType,
		searchLocation,
		searchPageLocation,
		setContentAndStatus,
		similarVendors,
		vendorRaw,
		isUserRegistered,
		userMaxGuestCount,
		vendorRecommendationDistanceAssignment,
		vrmAIPhotoAssignment,
		reportServerSideExperiment,
	} = props;
	const [modal, setModal] = useState('');
	const [initiator, setInitiator] = useState<
		{ sourceContent: InlineRfqSourceContent } | Record<string, unknown>
	>({});

	const recommendedVendors = useMemo(() => {
		return filterVendors(messagedVendors, similarVendors, userMaxGuestCount);
	}, [similarVendors, messagedVendors, userMaxGuestCount]);

	useEffect(() => {
		if (vendorRecommendationDistanceAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'Post Vendor Recommendation Distance Assignment:',
				vendorRecommendationDistanceAssignment,
			);
			reportServerSideExperiment(experiments.vendorRecommendationDistance);
		}
		const radius =
			vendorRecommendationDistanceAssignment === '70-miles' ? 70 : 25;

		const vendorsRequested = VRM_VENDORS_REQUESTED;

		if (vrmAIPhotoAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('VRM Smart Main Image: ', vrmAIPhotoAssignment);
			reportServerSideExperiment(experiments.VRMAIPhoto);
		}

		getSimilarVendors(
			vendorRaw,
			vendorsRequested,
			radius,
			searchLocation,
			searchPageLocation,
			pageType,
			vrmAIPhotoAssignment || undefined,
		);
	}, [
		getSimilarVendors,
		vendorRaw,
		searchLocation,
		searchPageLocation,
		pageType,
		reportServerSideExperiment,
		vendorRecommendationDistanceAssignment,
		vrmAIPhotoAssignment,
	]);

	useEffect(() => {
		if (modal === 'vrm' && recommendedVendors.length === 0) {
			redirectToUpsell(vendorRaw.name, setContentAndStatus, isUserRegistered);
		}
	}, [
		modal,
		recommendedVendors,
		isUserRegistered,
		vendorRaw?.name,
		setContentAndStatus,
	]);

	const toggleVRM = useCallback((status: boolean) => {
		if (!status) {
			setModal('');
		}
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: dependencies are correct
	const openModal = useCallback(
		(sourceContentValue: InlineRfqSourceContent) => {
			setInitiator({
				sourceContent: sourceContentValue,
			});
			setModal('vrm');
		},
		[setModal, setInitiator],
	);

	const onClose = useCallback(() => setModal(''), []);

	const value = useMemo(
		() => ({
			modal,
			openModal,
			setModal,
			onClose,
			recommendedVendors,
			toggleVRM,
			initiator,
		}),
		[modal, recommendedVendors, onClose, toggleVRM, openModal, initiator],
	);

	return (
		<RecommendedContext.Provider value={value}>
			{children}
		</RecommendedContext.Provider>
	);
};

const mapStateToProps = (state: State) => ({
	messagedVendors: state.messagedVendors.conversations,
	pageType: state.page.pageType,
	searchLocation: state.location,
	searchPageLocation: state.searchPageLocation,
	similarVendors: state.vrm.similarVendors,
	vendorRaw: state.vendor.vendorRaw as Raw,
	isUserRegistered: state.rfq.isUserRegistered,
	userMaxGuestCount: selectUserMaxGuestCount(state),
	vendorRecommendationDistanceAssignment:
		vendorRecommendationDistanceSelector(state),
	vrmAIPhotoAssignment: VRMAIPhotoSelector(state),
});

const mapDispatchToProps = {
	getSimilarVendors: getSimilarVendorsAction,
	setContentAndStatus: AlertActions.setContentAndStatus,
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
};

const RecommendedContextProvider = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ContextProvider);

export { RecommendedContextProvider };

export default RecommendedContext;
