import { PasswordModalV2Props } from '@components/auto-account-creation/passwordModalV2/PasswordModalV2';

import useBlur from '@hooks/useBlur';
import useKeypress from '@hooks/useKeypress';
import { State } from '@xo-union/tk-component-fields';
import { ChangeEvent, useRef, useState } from 'react';
import type { PasswordDialogProps } from '../../components/PasswordDialog/PasswordDialog';
import type { PasswordModalProps } from '../../passwordModal/PasswordModal';
import type {
	ButtonIsDisabled,
	HandlePasswordChange,
	PasswordRef,
	PasswordState,
} from '../../types';

type UsePasswordReturn = [
	PasswordRef,
	HandlePasswordChange,
	PasswordState,
	ButtonIsDisabled,
];
interface UsePassword {
	(
		props:
			| PasswordModalProps
			| PasswordModalV2Props
			| PasswordDialogProps
			| PasswordDialogProps,
	): UsePasswordReturn;
}

export const MIN_PASSWORD_LENGTH = 6;
export const MAX_PASSWORD_LENGTH = 20;

const usePassword: UsePassword = (props) => {
	const { handleSubmit, password, updatePassword } = props;
	const passwordRef = useRef<HTMLInputElement>(null);
	const [onBlur, shouldValidateMinimum] = useBlur(passwordRef);
	let passwordValue = password;

	const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		updatePassword(value);

		passwordValue = value;
	};

	const isValidPassword = (waitForBlur: boolean): State => {
		if (passwordValue.length > MAX_PASSWORD_LENGTH) {
			return 'invalid';
		}
		const validateMinimum = waitForBlur ? shouldValidateMinimum : true;

		if (validateMinimum && passwordValue.length < MIN_PASSWORD_LENGTH) {
			return 'invalid';
		}
		return 'neutral';
	};

	const passwordState = (waitForBlur = true) => isValidPassword(waitForBlur);

	const [buttonIsDisabled] = useKeypress(onBlur, handleSubmit, isValidPassword);

	return [passwordRef, handlePasswordChange, passwordState, buttonIsDisabled];
};

export default usePassword;
