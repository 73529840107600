import {
	TrackConfModalCtaClickProperties,
	TrackConfModalViewedProperties,
} from '@components/auto-account-creation/types';
import { Track } from 'types/analytics';
import { EVENT_CTA_CLICKED, EVENT_MODAL_VIEWED } from './constants';

const trackConfirmationModalViewed = (
	track: Track,
	properties: TrackConfModalCtaClickProperties,
) => {
	track({ event: EVENT_MODAL_VIEWED, properties });
};

const trackConfirmationModalCtaClick = (
	track: Track,
	properties: TrackConfModalViewedProperties,
) => {
	track({ event: EVENT_CTA_CLICKED, properties });
};
export { trackConfirmationModalViewed, trackConfirmationModalCtaClick };
