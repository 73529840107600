import { PERSONALIZED_QUOTE_HEADER_TEXT } from '@components/detailed-pricing/PricingCta';
import { useStorefrontFeatureBvcPlusPricing } from '@feature-flags/hooks/Storefront/useStorefrontFeatureBvcPlusPricing';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useAppSelector } from '@redux/hooks';

export const useDetailedPricingRfqSubheaderText = (headerText?: string) => {
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const isInDetailedPricing = useDetailedPricing();
	const isBvcPlusPricingEnabled = useStorefrontFeatureBvcPlusPricing();

	if ((!isInDetailedPricing && !isBvcPlusPricingEnabled) || !vendor) {
		return null;
	}

	if (isBvcPlusPricingEnabled) {
		return 'Use this secure form to reach out and share your wedding vision.';
	}

	if (headerText === PERSONALIZED_QUOTE_HEADER_TEXT) {
		return `Use this secure form to reach out to ${vendor.name} and ask for details about pricing.`;
	}

	return `Use this secure form to reach out to ${vendor.name} and share your wedding vision.`;
};
