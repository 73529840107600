import * as guards from '@api/helpers/guards';
import honeybadger from '@honeybadger-io/js';
import type { Notice } from '@honeybadger-io/js/dist/server/types/core/types';

const getMessage = (name: API.ErrorName): string => {
	if (guards.isGraphQLError(name)) {
		return `GraphQL query ${name} returned with an error`;
	}

	if (guards.isSuperagentError(name)) {
		return `Superagent response within ${name} was not a 200`;
	}

	return name;
};

// TODO: Parameter should not be any and response should not be asserted.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getError = <T extends Notice>(errorPayload: any): T => {
	if (guards.isInvalidResponse(errorPayload)) {
		return errorPayload.response as T;
	}
	return errorPayload;
};

const report = <U>(
	name: API.ErrorName,
	errorPayload: API.ErrorPayload,
	context?: U,
): void => {
	const message = getMessage(name);
	const error = getError(errorPayload);
	const errorContext = context || {};
	honeybadger.setContext(errorContext);
	honeybadger.notify(message, error);
	honeybadger.resetContext();
};

export default report;
