import { useAndroidOnMobileByPage } from '@hooks/use-android-on-mobile-by-page';
import * as ExperimentActions from '@redux/experiments';
import { selectAssignment } from '@redux/experiments/selectors';
import { useAppSelector } from '@redux/hooks';
import { googleMapsAPIKey } from '@settings';
import { experiments } from '@settings';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import RFQModalContext from '../../../contexts/RFQModalContext/RFQModalContext';
import { determineContent } from './utils';

const googleMapsJs = `https://maps.googleapis.com/maps/api/js?key=${googleMapsAPIKey}&libraries=places&v=3.36`;

export const MetaContent = ({
	buildLinkedData,
	canonical,
	description,
	faqData,
	host,
	isMobile = false,
	landingPage = '',
	next,
	noIndex,
	ogImage,
	pathname,
	prev,
	reportServerSideExperiment,
	title,
}) => {
	const { shouldIncludeMap } = useContext(RFQModalContext);
	const branchBannerAssignment = useAppSelector((state) =>
		selectAssignment(state, ['branchBanner']),
	);
	const content = determineContent(branchBannerAssignment, isMobile);

	const isAndroidOnMobileByPage = useAndroidOnMobileByPage();

	useEffect(() => {
		if (landingPage && branchBannerAssignment && isMobile) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Branch Banner Assignment: ', branchBannerAssignment);
			reportServerSideExperiment(experiments.branchBanner);
		}
	}, [
		branchBannerAssignment,
		isMobile,
		landingPage,
		reportServerSideExperiment,
	]);

	return (
		<Helmet>
			<title>{title} - The Knot</title>
			<meta content={description} name="description" />
			{prev && <link rel="prev" href={prev} />}
			<link rel="canonical" href={canonical} />
			{next && <link rel="next" href={next} />}
			{(host.match('distil') || host.match('akamai') || noIndex) && (
				<meta name="robots" content="noindex" />
			)}{' '}
			{/* Block bots from access distil url - distil-prod-fe or distil-prod-new-fe */}
			<meta charset="utf-8" /> {/* Encoding chars for HTML doc */}
			<meta content="ie=edge" httpEquiv="x-ua-compatible" />{' '}
			{/* Tells IE to use its latest rendering engine */}
			<meta content="width=device-width, initial-scale=1.0" name="viewport" />{' '}
			{/* Controls pages dimensions and scaling */}
			<meta name="mobile-web-app-capable" value="yes" />{' '}
			{/* Tells mobile web to run in full screen mode */}
			<meta content="theknot.com" property="og:site_name" />
			<meta content="article" property="og:type" />
			<meta content={title} property="og:title" />
			{ogImage && <meta content={ogImage} property="og:image" />}
			<meta content={description} property="og:description" />
			{pathname && <meta content={pathname} property="og:url" />}
			<meta
				data-rh="true"
				name="branch:deeplink:branch-banner"
				content={content}
			/>
			{content === 'banner-1' && !isAndroidOnMobileByPage && (
				<style type="text/css" data-testid="style">
					{'body.branch-banner-is-active { margin-top: 48px; }'}
				</style>
			)}
			{faqData && faqData.length > 0
				? buildLinkedData(faqData, title, pathname)
				: ''}
			{shouldIncludeMap && (
				<script defer type="text/javascript" src={googleMapsJs} />
			)}
			<link rel="preconnect" href="//union.theknot.com" />
			<link rel="dns-prefetch" href="//www.google-analytics.com" />
			<link rel="dns-prefetch" href="//www.googletagmanager.com" />
			<link rel="dns-prefetch" href="//js-agent.newrelic.com" />
			<link rel="preconnect" href="//media-api.xogrp.com" />
			<link rel="dns-prefetch" href="//cdn.branch.io" />
		</Helmet>
	);
};

MetaContent.propTypes = {
	buildLinkedData: PropTypes.func,
	canonical: PropTypes.string,
	description: PropTypes.string.isRequired,
	faqData: PropTypes.arrayOf(
		PropTypes.shape({
			question: PropTypes.string,
			answer: PropTypes.string,
		}),
	),
	next: PropTypes.string,
	ogImage: PropTypes.string,
	pathname: PropTypes.string,
	prev: PropTypes.string,
	title: PropTypes.string.isRequired,
	host: PropTypes.string.isRequired,
	noIndex: PropTypes.bool,
};

MetaContent.defaultProps = {
	buildLinkedData: () => {},
	canonical: '',
	faqData: [],
	next: null,
	ogImage: null,
	pathname: null,
	prev: null,
	noIndex: false,
};

export const mapStateToProps = (state) => ({
	faqData: state.faq.data,
	host: state?.requestInfo?.info?.host || '',
	isMobile: state.viewport.isMobile,
});

export const mapDispatchToProps = {
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
};

export default connect(mapStateToProps, mapDispatchToProps)(MetaContent);
