import { Carousel } from '@xo-union/ui-carousel';
import React, { FC } from 'react';
import Styles from './ContactedVendorsCarousel.scss';
import { RoundVendorCard } from './RoundVendorCard';
import { NextButton, PrevButton } from './buttons';

interface ContactedVendorsProps {
	contactedVendors: API.FESharedProfile[];
	sourcePage: string;
}

export const ContactedVendors: FC<ContactedVendorsProps> = ({
	contactedVendors,
	sourcePage,
}) => {
	return (
		<div className={Styles.carouselWrapper}>
			<Carousel
				gutter={12}
				minSlideWidth={123}
				maxSlideWidth={123}
				renderNextButton={NextButton}
				renderPrevButton={PrevButton}
			>
				{contactedVendors.map((vendor) => (
					<RoundVendorCard
						key={vendor.id}
						vendor={vendor}
						sourcePage={sourcePage}
					/>
				))}
				<div />
			</Carousel>
		</div>
	);
};
